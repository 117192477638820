<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: manualFeedGraph.vue
Description: This file is the chart component that displays manual feed data. It represents different feed types with chart markers.Here x axis is the feeding date and y axis is the amount of feed dispensed
-->
<template>
  <er-card
    v-loading="loading"
    element-loading-background="white"
    ref="manual-feed-graph"
  >
    <layout-toolbar slot="header" gap="5">
      <p class="card-title">{{ `${$t("Comn_manual_feed")}` }}</p>
      <div class="filler"></div>
      <er-date-picker
        v-model="dateRange"
        type="daterange"
        unlink-panels
        :timeZoneString="getUserTimeZoneString"
        :disableDateMethod="chm__disableDateMethod"
        :availableInterval="chm__availableInterval"
        :clearable="false"
        :format="this.upm__getFormatDateString"
        @change="handleValueChange($event, 'dateChange')"
      ></er-date-picker>
    </layout-toolbar>
    <el-row :key="$i18n.locale">
      <high-charts
        :options="chartOptions"
        ref="highcharts"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import chartMixin from "@/mixins/chartMixin";
import { manualFeedGraph } from "./chartOptions";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";

export default {
  mixins: [
    errorHandlerMixin,
    chartMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      loading: false,
      dateRange: [],
      params: {
        from_date: "",
        to_date: "",
        month_of: null,
        week_of: null,
        get_all: true
      },
      newFeedRecord: {
        pond_id: "",
        date: "",
        feed_dispensed: 0,
        feed_id: "",
        modified: false
      },
      chartOptions: { ...manualFeedGraph },
      pondSlotFeedLogs: [],
      dateFormatObject: {
        "dd/MM/yyyy": "%d/%m/%Y",
        "dd MMM": "%d %b",
        "dd MMM, yy": "%d %b, %y"
      }
    };
  },
  mounted() {
    this.chm__registerResizeObserver("manual-feed-graph");
  },
  beforeDestroy() {
    this.chm__unRegisterResizeObserver("manual-feed-graph");
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    ...mapGetters("pondDetails", {
      getPondScheduleGraphSlotWiseFeed: "getPondScheduleGraphSlotWiseFeed",
      getManualFeedData: "getManualFeedData"
    }),
    chm__defaultDateObjDtRangeForCurrCulture() {
      return this.chm__getDateObjArrOfCultureDateRange;
    }
  },
  methods: {
    ...mapActions("pondDetails", {
      fetchManualFeedData: "fetchManualFeedData"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent(chm__pondId) {
      this.loading = true;
      const yAxis = "Comn_feed_kg";
      try {
        await this.fetchManualFeedData({
          deviceType: "pond",
          params: this.params
        });
        this.pondSlotFeedLogs = this.getManualFeedData;
        this.chm__initAxisTextKeys("Comn_date", yAxis);
        this.chm__initChartLang();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    normalizeRecords(pondFeedDaysWise) {
      const manualFeedRecords = pondFeedDaysWise || [];
      const normalizeRecords = manualFeedRecords.reduce(
        (acc, { pond_id, date, feeds, doc }) => {
          const arrFeeds = feeds.map(
            ({ feed_dispensed, feed_id: feed }, index) => {
              const newFeedRecord = this.$lodash.cloneDeep(this.newFeedRecord);
              newFeedRecord.date = date;
              newFeedRecord.doc = doc;
              newFeedRecord.modified = false;
              newFeedRecord.pond_feed_index = index;
              newFeedRecord.feed_id = feed._id;
              newFeedRecord.feed_name = feed.feed_type
                ? `${feed.name}-${feed.feed_type}`
                : feed.name;
              newFeedRecord.feed_dispensed = feed_dispensed;
              return newFeedRecord;
            }
          );
          acc.push(...arrFeeds);
          return acc;
        },
        []
      );
      return normalizeRecords;
    },
    pondFeedGroupByFeedName(pondFeedDaysWise, dateRange) {
      const milliSecs = date => date.getTime();
      const dateToManualFeed = {};
      const dateToFeedName = {};
      let dayStart = this.dhm__dateUtilsLib.parseISO(
        dateRange[0] + "T00:00:00.000Z"
      );
      const dayEnd = this.dhm__dateUtilsLib.parseISO(
        dateRange[1] + "T00:00:00.000Z"
      );
      while (dayStart <= dayEnd) {
        dateToManualFeed[milliSecs(dayStart)] = {};
        dateToFeedName[milliSecs(dayStart)] = "none";
        dayStart = this.dhm__dateUtilsLib.add(dayStart, { days: 1 });
      }
      const normalizeRecords = this.normalizeRecords(pondFeedDaysWise);
      normalizeRecords.forEach(dayFeed => {
        const date = this.dhm__dateUtilsLib.parseISO(dayFeed.date);
        dateToManualFeed[milliSecs(date)][dayFeed.feed_name] = Number(
          dayFeed.feed_dispensed.toFixed(2)
        );
        dateToManualFeed[milliSecs(date)].doc = Number(
          dayFeed.doc
        );
      });
      const groupByFeed = Object.keys(dateToManualFeed).reduce(
        (groupByFeed, currDay) => {
          const feedRecords = dateToManualFeed[currDay];
          Object.keys(feedRecords).reduce((acc, currFeedKey) => {
            if (currFeedKey !== 'doc') {
              if (!acc[currFeedKey]) {
                acc[currFeedKey] = [];
              }
              acc[currFeedKey].push({
                x: Number(currDay),
                y: feedRecords[currFeedKey],
                z: feedRecords.doc
              });
              return acc;
            }
          }, groupByFeed);
          return groupByFeed;
        },
        {}
      );
      Object.values(groupByFeed).forEach(FeedTypeManualFeed => {
        FeedTypeManualFeed = FeedTypeManualFeed.sort((a, b) => a.x - b.x);
      });
      return { groupByFeed };
    },
    chm__initChart() {
      let result = {};
      let feedLimit = [];
      this.chartOptions.tooltip.footerFormat = '<tr><td style="color:#255379">DOC: </td><td style="text-align: right"><b>{point.z}</b></td></tr></table>';
      this.chartOptions.tooltip.xDateFormat = this.dateFormatObject[
        this.upm__getFormatDateString
      ];
      if (this.pondSlotFeedLogs.length === 0) {
        this.chartOptions.series = [];
        return;
      }
      result = this.pondFeedGroupByFeedName(this.pondSlotFeedLogs, [
        this.params.from_date,
        this.params.to_date
      ]);
      feedLimit = result.groupByFeed;
      this.initChartSeries(feedLimit);
    },
    initChartSeries(feedLimit) {
      this.chartOptions.series = [];
      const arrFeedTypes = Object.keys(feedLimit);
      const noOfFeedTypes = arrFeedTypes.length;
      const feedColors = this.getNColors(noOfFeedTypes);
      arrFeedTypes.forEach((feedType, index) => {
        this.chartOptions.series.push({
          name: feedType,
          data: feedLimit[feedType],
          type: "line",
          marker: {
            enabled: true,
            radius: 4
          },
          color: feedColors[index],
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "rgba(124, 181, 236,1)"],
              [1, "rgba(255,255,255,.25)"]
            ]
          },
          visible: true
        });
      });
    },
    async handleValueChange(currentSelectedValues, type = '') {
      this.params.from_date = currentSelectedValues[0];
      this.params.to_date = currentSelectedValues[1];
      await this.initComponent(this.chm__pondId);
      if (type === 'dateChange') {
        this.mixPanelEventGenerator({ eventName: "Ponds - Dashboard - Manual Feed Graph - Date Filter" });
      }
    },
    getNColors(numOfColors) {
      const arrColors = [];
      const initL = (100 - 20) / numOfColors;
      const initH = (360 - 60) / numOfColors;
      for (let i = 1; i <= numOfColors; i++) {
        arrColors.push(this.hsl2rgb(initH * i, 96 * 0.01, initL * i * 0.01));
      }
      return arrColors;
    },
    hsl2rgb(h, s, l) {
      const a = s * Math.min(l, 1 - l);
      const f = (n, k = (n + h / 30) % 12) =>
        l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return this.rgbToHex(f(0), f(8), f(4));
    },
    rgbToHex(r, g, b) {
      return (
        "#" +
        [r, g, b]
          .map(x =>
            Math.round(x * 255)
              .toString(16)
              .padStart(2, 0)
          )
          .join("")
      );
    }
  }
};
</script>
<style lang="scss"></style>
